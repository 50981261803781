import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousel = document.querySelector('.home-header__carousel');
  const form = document.querySelector('.home-header__form');

  if (carousel) {
    const args = {
      slidesPerView: 1,
      loop: false,
      pagination: {
        el: '.home-header__pagination',
        clickable: true,
        type: 'bullets',
        dynamicBullets: true,
      },
    };
    const slides = document.querySelectorAll('.home-header__slide');

    if (1 < slides.length) {
      args.loop = true;
    }

    new Swiper(carousel, args);
  }

  if (form) {
    form.addEventListener('submit', (e) => {
      e.preventDefault();
      const input = form.querySelector('select');
      if (input.value) {
        window.location.href = input.value;
      }
    });
  }
});
